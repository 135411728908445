import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest, HttpParams } from '@angular/common/http';
import { Globals } from '../globals';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {
  private token; 
  private api = 'https://api.vimeo.com/me/videos';
  public authCode = '';
  vimeoObsShare: Observable<string>;
  private initHeaders = new HttpHeaders({
    'Authorization': 'bearer ' + this.globals.vimeoToken,
    'Content-Type': 'application/json',
    'Accept': 'application/vnd.vimeo.*+json;version=3.4'
  });

  vimeoResult: string;
  public client;
  public videoId = null;
  private vimeoLink = new BehaviorSubject('');
  constructor(private http: HttpClient, private globals: Globals) {
    this.loadToken();
  }
/* 
    initAuthVimeo(idCategoria) {
      let code = this.generarCodigo(10);
      localStorage.setItem('vimeo_code',code);
      localStorage.setItem('idVimeoCategoria', idCategoria);
      window.open(`https://api.vimeo.com/oauth/authorize?response_type=token&client_id=${this.globals.client_id}&redirect_uri=${this.globals.redirect_uri}&state=${code}&scope=${this.globals.scopes}`);
    }
    getAccessToken() {
      const initHeaders = new HttpHeaders({'Authorization': 'basic ' + this.globals.client_base64 });
      initHeaders.append('Content-Type', 'application/json');
      initHeaders.append('Accept', 'application/vnd.vimeo.*+json;version=3.4');
      let body = {
        "grant_type": "authorization_code",
        "code": "aefc2cc7f83f3780a27eeca05089916b73fc9da7",
        "redirect_uri": `${this.globals.redirect_uri}`
      }
      return this.http.post(`https://api.vimeo.com/oauth/access_token`,body);
    } */
  updateVimeoLink(val) {
    this.vimeoLink.next(val);
  }
  createVimeo(options, fileSize): Observable<any> {
    // CUSTOM INIT BODY
    const initBody = {
      'upload' : {
        'approach' : 'tus',
        'size' : fileSize
      },
      'privacy': {
        'view': 'disable',
        'download': false,
        'embed' : 'public',
      },
      'name': options.videoName,
      'description': options.videoDescription,
      'embed' : {
        'buttons': {
          'embed': false,
          'like' : false,
          'share' : false,
          'watchlater' : false,
        },
        'logos': {'vimeo' : false},
        'title' : {
          'owner': 'hide',
          'portrait' : 'hide'
        }
      }
    };
    if (this.vimeoResult) {
      return new Observable<any>(observer => {
        observer.next(this.vimeoResult);
        observer.complete();
      });
    } else if (this.vimeoObsShare) {
      return this.vimeoObsShare;
    } else {
      return this.http.post( this.api, initBody, { headers: this.initHeaders});
    }
  }

  vimeoUpload(url, file: File, progreso): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({'Tus-Resumable': '1.0.0',
      'Upload-Offset': progreso.toString(),
      'Authorization': 'bearer ' + this.globals.vimeoToken,
      'Accept': 'application/vnd.vimeo.*+json;version=3.4',
      'Content-Type': 'application/offset+octet-stream'});
    const params = new HttpParams();
    const options = {
      params: params,
      reportProgress: true,
      headers: headers
    };
    const req = new HttpRequest('PATCH', url, file, options);
    return this.http.request(req);
  }

  vimeoDelete(idVideo): Observable<any> {
    return this.http.delete( `https://api.vimeo.com/videos/${idVideo}`, { headers: this.initHeaders});
  }
  vimeoEditar(idVideo, options): Observable<any> {
    const initBody = {
      'privacy': {
        'view': 'disable',
        'download': false,
        'embed' : 'public',
      },
      'name': options.videoName,
      'description': options.videoDescription,
      'embed' : {
        'buttons': {
          'embed': false,
          'like' : false,
          'share' : false,
          'watchlater' : false,
        },
        'logos': {'vimeo' : false},
        'title' : {
          'owner': 'hide',
          'portrait' : 'hide'
        }
      }
    };
    return this.http.patch(`https://api.vimeo.com/videos/${idVideo}`, initBody, { headers: this.initHeaders});
  }
  getVideoId(uri) {
    return uri.substr(uri.lastIndexOf('/') + 1);
  }
  verificarVideoSubido(uploadLink) {
    const headers =  new HttpHeaders({
    'Tus-Resumable': '1.0.0',
    'Authorization': 'bearer ' + this.globals.vimeoToken,
    'Accept': 'application/vnd.vimeo.*+json;version=3.4',
    'Content-Type': 'application/offset+octet-stream'});
      return this.http.head(uploadLink,{headers: headers, observe: 'response'}).pipe(map(res => res.headers.get('Upload-Offset')) );
  }

  setStorageToken(token) {
    this.token = token;
  }
  getStorageToken() {
    return this.token;
  }
  loadToken() {
    this.token = localStorage.getItem('access_token');
  }
  generarCodigo(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.toUpperCase();
  }
}
