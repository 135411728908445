import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VimeoService } from '../../services/vimeo.service';
import { UsuariosService } from '../../services/usuarios.service';
import * as moment from 'moment';
@Component({
  selector: 'app-redirect-vimeo',
  templateUrl: './redirect-vimeo.component.html',
  styleUrls: ['./redirect-vimeo.component.css']
})
export class RedirectVimeoComponent implements OnInit {
  private state = '';
  private idCategoria;
  private access_token;
  constructor(private router: Router, private activeRoute: ActivatedRoute, private usuarioService: UsuariosService,private vimeo: VimeoService) { }

  ngOnInit() {
    this.activeRoute.fragment.subscribe(params => {
      let { state, access_token } = this.generarParametros(params);
      this.access_token = access_token;
      this.state = state;
      this.verificarLink();
    });
  }

  verificarLink() {
    let storageCode = localStorage.getItem('vimeo_code');
    if(storageCode != this.state) {
      return this.router.navigate(['/catalogos/dashboard']);
    }
    this.idCategoria = localStorage.getItem('idVimeoCategoria');
    this.setExpiration();
    localStorage.setItem('access_token', this.access_token);
    this.vimeo.setStorageToken(this.access_token);
    this.router.navigate([`/catalogos/clases`]);
/* 
    this.vimeo.getAccessToken().subscribe((res: any) => {
      console.log(res);
      localStorage.setItem('access_token', res.access_token);
      this.vimeo.accessToken = res.access_token;
      this.router.navigate([`/catalogos/categorias/${this.idCategoria}/clases`]);
    }, err => {
      console.log(err);
      this.usuarioService.showToastr('error', err)});
  } */
  }
  generarParametros(params) {
    let arrParams = params.split('&');
    let accessToken = arrParams[0].split('=')[1];
    let state = arrParams[arrParams.length-1].split('=')[1];
    return { access_token: accessToken,state}
  }
  setExpiration(){
    let hoy = moment();
    let fechaExp = hoy.add(3600,'s');
    localStorage.setItem('exp_date',fechaExp.toISOString());
  }
}
