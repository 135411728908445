import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  apiUrl: string = 'https://api.susyarambula.com';
  permisosdisponibles = [
    'CLIENTES',
    'DASHBOARD',
    'USUARIOS',
    'PAQUETES',
    'CATEGORIAS',
    'CLASES',
    'VIDEOS'
  ];
  //Vimeo
  scopes = 'private%20create%20edit%20delete%20upload';
  vimeoToken = '6f8d15f87260e17cffe15dc81bfc0100';
  redirect_uri = `${window.location.origin}/redirectVimeo`;
  client_secret = 'NEuidKEsV7wPzE5WNBVlmlohutpYyJ5GD7cB6HiNFav6e/GW6tO0C4pIAWmYU95OBYs862PuoApAs77vepm80cHtl1hU+xqr+w9Accgfmgqg8e528q5ozhIDB+J96XpZ';
  client_id = '9f46c7a373a7792ef983c4141320909cb7cd1961';
  client_base64 = 'KDlmNDZjN2EzNzNhNzc5MmVmOTgzYzQxNDEzMjA5MDljYjdjZDE5NjE6TkV1aWRLRXNWN3dQekU1V05CVmxtbG9odXRwWXlKNUdEN2NCNkhpTkZhdjZlL0dXNnRPMEM0cElBV21ZVTk1T0JZczg2MlB1b0FwQXM3N3ZlcG04MGNIdGwxaFUreHFyK3c5QWNjZ2ZtZ3FnOGU1MjhxNW96aElEQitKOTZYcFo='
}
