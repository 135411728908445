import { Component } from '@angular/core';

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  children?: ChildrenItems[];
  permission?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab?: string;
  type?: string;
  permission?: string;
  icontype: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Inicio",
    type: "link",
    icontype: "nc-icon nc-bank",
  },
  {
    path: "/clientes",
    title: "Clientes",
    type: "link",
    icontype: "fal fa-users",
    permission: "CLIENTES",
  },
  {
    path: "/catalogos/videos",
    title: "Videos",
    icontype: "fal fa-video",
    permission: "VIDEOS",
    type: "link",
  },
  {
    path: "/catalogos",
    title: "Catálogos",
    type: "sub",
    icontype: "fal fa-archive",
    children: [
      {
        path: "categorias",
        title: "Categorías",
        icontype: "fal fa-th",
        permission: "CATEGORIAS",
      },
      {
        path: "clases",
        title: "Clases",
        icontype: "fal fa-heartbeat",
        permission: "CLASES",
      },
      {
        path: "paquetes",
        title: "Paquetes",
        icontype: "fal fa-cart-arrow-down",
        permission: "PAQUETES",
      },
      {
        path: "usuarios",
        title: "Usuarios",
        icontype: "fal fa-user-cog",
        permission: "USUARIOS",
      },
    ],
  },
];

@Component({

  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
  public menuItems: any[];
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }
  private token = JSON.parse(localStorage.getItem('jwtToken'));
  public perm = this.token.user.permisos;
  public user = this.token.user;

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
}
