import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { RedirectVimeoComponent } from './components/redirect-vimeo/redirect-vimeo.component';

export const AppRoutes: Routes = [
    {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [ AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './components/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'clientes',
        loadChildren: './components/clientes/clientes.module#ClientesModule',
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: 'CLIENTES',
            redirectTo: '/dashboard'
          }
        }
      },
      {
        path: 'catalogos',
        loadChildren: './components/catalogos/catalogos.module#CatalogosModule',
      },
      {
        path: 'reportes',
        loadChildren: './components/reportes/reportes.module#ReportesModule',
      }
    ]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'redirectVimeo',
    component: RedirectVimeoComponent,
  },

];
