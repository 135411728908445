import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from 'rxjs';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let jwtoken = JSON.parse(localStorage.getItem('jwtToken'));
        if(jwtoken){

            const req = request.clone({
                setHeaders: {
                    Authorization: this.esVimeo(request) ? request.headers.get('Authorization') : `JWT ${jwtoken.token}`
                },
            });
            return next.handle(req);
        } else {
          return next.handle(request);
        }
    }

    esVimeo(req) {
        return req.headers.get('accept') === 'application/vnd.vimeo.*+json;version=3.4' ? true : false;
    }
}
